import React, { useState } from 'react';
import './Contacto.css'; // Archivo CSS para estilos
import Swal from 'sweetalert2';


function ContactForm() {
  // Estado para almacenar los valores del formulario
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    telefono: ''
  });

  // Función para manejar cambios en los inputs del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://servidoraurapazfuneraria.com/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
        timeout: 10000 // Establecer el tiempo de espera en milisegundos 
      });
      if (response.ok) {
        Swal.fire({
          title: '¡MENSAJE ENVIADO CON EXITO!',
          text: 'En un momento nos pondremos en contacto con usted',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        });
        // Reiniciar el estado del formulario después del envío
        setFormData({
          name: '',
          email: '',
          message: '',
          telefono: ''
        });
      } else {
           Swal.fire({
      title: 'Error!',
      text: 'Hubo un error al procesar tu solicitud.',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Hubo un error al procesar tu solicitud., posiblemente al ennviar el post.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };
  

  return (
    <div className="contact-form-container">
      <h2>Contactanos</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <input
          type="text"
          name="name"
          placeholder="Nombre"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Correo Electrónico"
          value={formData.email}
          onChange={handleChange}
          required
        />
                <input
          type="tel"
          name="telefono"
          placeholder="Número de celular"
          value={formData.telefono}
          onChange={handleChange}
          required
        />
        <h6 className='Titulomensaje'>Por favor cuéntenos en que servicios está interesado</h6>
        <textarea
          name="message"
          placeholder="Mensaje"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        <button className='BotonContactanos' type="submit">Enviar</button>
      </form>
    </div>
  );
}

export default ContactForm;
