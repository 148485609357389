import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Carrusel.css'
import  presentacion1lolo  from '../../assets/img/presentacion1lolo.jpg';
import  presentacion2lolo  from '../../assets/img/3lolobd1.jpg';
import  presentacionfinal3  from '../../assets/img/presentacionfinal3.jpg';

const CarouselComponent = () => {
    return (

        <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    
                        <p className="titulo" id='Uno'><br></br>Aura Paz<br></br>Funeraria y sala de velación</p>
                    
                       
                    <img src={presentacion1lolo} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                <p className="titulo">Donde el silencio habla y los recuerdos florecen</p>

                    <img src={presentacion2lolo} alt="..." />
                </div>
                <div className="carousel-item">
                <p className="titulo">  sembrando consuelo en momentos de pérdida.</p>

                    <img src={presentacionfinal3} alt="..." />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Atras</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Siguiente</span>
            </button>
        </div>
    );
};

export default CarouselComponent;