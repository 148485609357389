
function Botones(props) {
    //scroll de los id


    return (
        <button className="BotonesDeDesplazamientos" id={props.identificador}>{props.Nombre}</button>
    );
}

export default Botones;