import './footer.css'
//import imagen from '../../assets/img/LogoSoloAuraPazicono.ico'

function Footer() {
    const handleSelectChange = (event) => {
        const targetId = event.target.value;
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop,
                behavior: 'smooth'
            });
        }
    };
    return (

        <footer>
            <div className='DatosFuneraria'>

                <div className='DatosDecontacto'>
                    <h5>Datos de contacto</h5>
                    Correo electrónico: Aurapazfuneraria@gmail.com<br></br>
                    Numero de teléfono: +507 6682-6114<br></br>
                    Dirección: Penonomé, Calle AV Héctor Conte Bermúdez, al lado de credicorp bank<br></br>
                    Horario de atención: 24/7 Para urgencias y de 8:00 AM a 5:00 PM Oficinas <br></br>


                </div>
                <div className='Botonesfooter'>
                    Dejanos tus datos nosotros te   <button className='BotonesDeDesplazamientos' id="Contactanos"> contactamos</button><br></br><br></br>
                    Conoce nuestros

                    <select className='BotonesDeDesplazamientos' onChange={handleSelectChange} defaultValue="">
                        <option value='planAnual'>Servicios</option>
                        <option value='planAnual'>Plan Anual</option>
                        <option value='planMensual'>Otros Planes</option>
                    </select>


                    <br></br><br></br>
                    Acerca  de  <button className='BotonesDeDesplazamientos' id='Quienes_Somos'> Nosotros</button>

                </div>
                <div className='nombrefooter'>
                    <br></br>
                    AURA PAZ <br></br>
                    FUNERARIA Y SALA DE VELACIÓN <br></br>


                </div>

            </div>



        </footer>


    );
}

export default Footer;
