
import "./Botones.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons';
function BotonFlotante() {
    return (
        <a href="https://api.whatsapp.com/send?phone=50766826114&text=Hola,%20Vi%20su%20página%20web%20y%20estoy%20interesado%20en%20saber%20de%20sus%20servicios" className="flotanteContacto">
             <FontAwesomeIcon icon={faSquareWhatsapp} />
        </a>
    );
}

export default BotonFlotante;