import React, { useEffect } from "react";
import "./QuienesSomos.css";
import "https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js";
import "https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js";
import Unicos from "../LoQueNosHaceDiferentes/LoQueNosHaceDiferentes";


function QuienesSomos() {
  return (
    <main>
      <div className="IntroduccionQuienesSomos">
        Somos una entidad Funeraria donde entendemos que despedirse de un ser
        querido es uno de los momentos más difíciles en la vida. Por eso, nos
        dedicamos a proporcionar un refugio de paz y consuelo en esos momentos
        de dolor y reflexión. Somos el hogar donde el amor perdura y los
        recuerdos encuentran un lugar para florecer.
        <br></br>
        Nuestra misión es abrazar a cada familia con compasión, respeto y
        atención personalizada en cada paso del camino. Desde el primer momento
        en que nos contactas, hasta más allá del último adiós, estamos aquí para
        brindarte el apoyo que necesitas.
      </div>
      <br></br> <br></br>
      <center>
        <h4>Lo que nos hace unicos</h4>
      </center>
      <br></br> <br></br>
      <div className="QuienesSomosUnicos">
        <Unicos />
      </div>
    </main>
  );
}

export default QuienesSomos;
