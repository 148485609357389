import React, { useState, useEffect } from 'react';
import './Alert.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Alert = () => {
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        setShowAlert(true);
    }, []);

    const closeAlert = () => {
        setShowAlert(false);
    };

    return (
        <div className={`alert ${showAlert ? 'show' : 'hide'}`}>
            <div className="alert-content">
                <center>
                <br /><br />
                    <h4> SERVICIOS FUNEBRES DE NECESIDAD INMEDIATA </h4> <br />
                    <h5>  DESDE B/:450.00 </h5><br />
                    <h6> Ataud , levantamiento, morgue, manipulación del cuerpo, arreglo floral, velas, recordatorios, accesorios fúnebres, carroza para sepelio y sala de velación.</h6><br /><h6>Trámite de acta de defunción</h6><br /><h6>Trámite de recuperación de CSS</h6> <br />
                    <h4>Comunícate con nosotros.</h4><br />
                   
                    <a href="https://api.whatsapp.com/send?phone=50766826114&text=Hola,%20Vi%20su%20página%20web%20y%20estoy%20interesado%20en%20saber%20de%20sus%20servicios"> <h6>Celular:6682-6114</h6> <FontAwesomeIcon icon={faWhatsapp} /> </a>
                    <br />
                    <h6>Telefono:983-2251</h6>

                    <h5>SERVICIOS A NIVEL NACIONAL</h5>
                </center>
                <span className="close-btn" onClick={closeAlert}>Cerrar</span>
            </div>
        </div>
    );
};

export default Alert;
