
import Inicio from './pages/Home/Inicio'

function App() {
  return (
    <div className="App">
      <Inicio />
    
    </div>
  );
}

export default App;
