import './nav.css'; // Asegúrate de que el nombre del archivo CSS coincida
import Botones from '../Botones/Botones';
import imagen from '../../assets/img/LogoAura.png';
import React, { useState, useEffect } from 'react';

function BarraDeNavegacion() {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSelectChange = (event) => {
        const targetId = event.target.value;
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    return (
        <nav className={`barraDeNavegacion ${isSticky ? 'sticky' : ''}`}>
            <div className='Logo'>
                <img src={imagen} alt="" />
            </div>
            <div className='Botones'>
                <Botones  identificador="Inicio" Nombre="Inicio" />
                <Botones identificador="Quienes_Somos" Nombre="Nosotros" />

                <select className='SelectServicios' onChange={handleSelectChange} defaultValue="">
    <option value=''>Servicios</option>
    <option value='planAnual'>Plan Anual</option>
    <option value='planMensual'>Otros Planes</option>
</select>

                <Botones identificador="Contactanos" Nombre="Contáctanos" />
            </div>
        </nav>
    );
}

export default BarraDeNavegacion;
