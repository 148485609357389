import "./inicio.css";
import Nav from "../../Components/Header/Nav";
import Carrusel from "../../Components/Carrusel/Carrusel";
import Paquetes from "../../Components/Paquetes/Paquetes";
import PlanMensual from "../../Components/Paquetes/PlanMensual";
import Contactanos from "../../Components/Contactanos/Contacto";
import QuienesSomos from "../../Components/QuienesSomos/QuienesSomos";
import Footer from "../../Components/Footer/Footer";
import BotonFlotante from "../../Components/Botones/BotonFlotante";
import Alerta from "../../Components/Alerta/Alerta";
import Fotos3D from "../../Components/3DFotos/3DFotor";
import React, { useEffect } from "react";
import VideoQuienesSomos from "../../Components/video/video";

function Inicio() {
  useEffect(() => {
    const inicioElements = document.querySelectorAll(
      ".BotonesDeDesplazamientos"
    );

    inicioElements.forEach((element) => {
      element.addEventListener("click", () => {
        console.log(element.id);
        switch (element.id) {
          case "Inicio":
            var Inicio = document.getElementById("Carrusel");
            Inicio.scrollIntoView({ behavior: "smooth" });
            break;
          case "Quienes_Somos":
            var QuienesSomos = document.getElementById("QuienesSomos");
            QuienesSomos.scrollIntoView({ behavior: "smooth" });
            break;
          case "planAnual":
            var Servicios = document.getElementById("paquetes");
            Servicios.scrollIntoView({ behavior: "smooth" });
            break;
          case "planMensual":
            var planMensual = document.getElementById("planMensual");
            planMensual.scrollIntoView({ behavior: "smooth" });
            break;
          case "Contactanos":
            var Contactanos = document.getElementById("Contactanosini");
            Contactanos.scrollIntoView({ behavior: "smooth" });
            break;

          default:
            break;
        }

        console.log("Se hizo clic en el elemento con clase 'Inicio'");
      });
    });
  }, []);

  return (
    <div className="Inicio">
      <Alerta />
      <BotonFlotante />
      <Nav />
      <div className="Carrusel" id="Carrusel">
        <Carrusel />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div id="QuienesSomos" className="QuienesSomos">
        <center>
          <h3>¿Quiénes somos?</h3>
        </center>
        <QuienesSomos />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      

      <div className="videoquienesomos">
        <VideoQuienesSomos />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div id="planAnual" className="paquetes">
        <center>
          <h3>PLANES ANUALES A NIVEL NACIONAL</h3>
          <br />
        </center>
        <Paquetes />
        <br />
        <br />
        <div className="planMensual" id="planMensual">
          <center>
            <h3>Otros Planes</h3>
            <br />
          </center>
          <PlanMensual />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div id="Contactanosini" className="Contactanos">
        <center>
          <h3>Déjanos Asesorarte</h3>
          <br />
        </center>
        <Contactanos />
      </div>
      <br />
      <br /> <br />
      <br />
      <div className="fotos3d">
        <Fotos3D />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default Inicio;
