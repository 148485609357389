import React from 'react';
import './Paquetes.css'; // Asegúrate de tener tu archivo de estilos CSS importado

function Accordion() {
  return (
    <ul className="c-accordion">
      <li id="cf" className="c-accordion__item" style={{ '--cover': `url(https://images.pexels.com/photos/7317916/pexels-photo-7317916.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)` }}>
        <a href="#cf" className="c-accordion__action">
          <div className="c-accordion__content">
            <h2 className="c-accordion__title c-accordion__title--hero c-accordion__title--hover-show"> Plan Anual <br></br><br></br><br></br> BRONCE </h2>
            <p className="c-accordion__description">
              <h6>Mínima cuota anual por la protección de todo su grupo familiar.</h6><br></br>

              <table>
                <tr>
                  <th>
                  Bronce (Individual)
                  </th>
                </tr>
                <tr>
                  <td className='precios'>
                    38,00
                  </td>
                </tr>
                <tr>
                  <th>
                  Bronce (Familiar)
                  </th>
                </tr>
                <tr>
                  <td className='precios'> 
                    48,00
                  </td>
                </tr>
                <tbody>
                <tr>
                  <td>
                  -Cobertura inmediata por muerte violenta o accidental y después de 180 días por muerte natural.
                  </td>
                </tr>

                <tr>
                  <td>
                  -Servicios de cremación o sepelio, ataúd o urna, levantamiento, morgue, manipulación del cuerpo, arreglo floral, velas, recordatorios, accesorios fúnebres, carroza para sepelio y sala de velación.
                  </td>
                </tr>
                <tr>
                  <td>
                  -Trámite de acta de defunción
                  </td>
                </tr>
                <tr>
                  <td>
                  -Trámite de recuperación de CSS
                  </td>
                </tr>
                <tr>
                  <td>
                  *Pago único
                  </td>
                </tr>
                </tbody>
              </table>
             
            </p>
          </div>
          <div className="c-accordion__aside">
            <h2 className="c-accordion__title c-accordion__title--hover-hide"></h2>
          </div>
        </a>
      </li>
      <li id="corp" className="c-accordion__item" style={{ '--cover': `url(https://images.pexels.com/photos/7317671/pexels-photo-7317671.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)` }}>
        <a href="#corp" className="c-accordion__action">
          <div className="c-accordion__content">
            <h2 className="c-accordion__title c-accordion__title--hero c-accordion__title--hover-show"> Plan Anual <br></br> <br></br>PLATA </h2>
            <p className="c-accordion__description">
              <h6>Mínima cuota anual por la protección de todo su grupo familiar.</h6><br></br>
              <table>
                <tr>
                  <th>
                  Plata (Individual)
                  </th>
                </tr>
                <tr>
                  <td className='precios'>
                  51,00
                  </td>
                </tr>
                <tr>
                  <th>
                  Plata (Familiar)
                  </th>
                </tr>
                <tr>
                  <td className='precios'>
                    62,00
                  </td>
                </tr>
                <tbody>
                <tr>
                  <td>
                  -Cobertura inmediata por muerte violenta o accidental y después de 180 días por muerte natural.
                  </td>
                </tr>

                <tr>
                  <td>
                  -Servicios de cremación o sepelio, ataúd o urna, levantamiento, morgue, manipulación del cuerpo, arreglo floral, velas, recordatorios, accesorios fúnebres, carroza para sepelio y sala de velación.
                  </td>
                </tr>
                <tr>
                  <td>
                  -Trámite de acta de defunción
                  </td>
                </tr>
                <tr>
                  <td>
                  -Trámite de recuperación de CSS
                  </td>
                </tr>
                <tr>
                  <td>
                  -Ayuda psicológica (3 sesiones)
                  </td>
                </tr>
                <tr>
                  <td>
                  *Pago único
                  </td>
                </tr>
                </tbody>
              </table>
            </p>
          </div>
          <div className="c-accordion__aside">
            <h2 className="c-accordion__title c-accordion__title--hover-hide"></h2>
          </div>
        </a>
      </li>
      <li id="lead" className="c-accordion__item" style={{ '--cover': `url(https://images.pexels.com/photos/7317718/pexels-photo-7317718.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)` }}>
        <a href="#lead" className="c-accordion__action">
          <div className="c-accordion__content">
            <h2 className="c-accordion__title c-accordion__title--hero c-accordion__title--hover-show"> Plan Anual <br></br><br></br>ORO </h2>
            <p className="c-accordion__description">
              <h6>Mínima cuota anual por la protección de todo su grupo familiar.</h6><br></br>
              <table>
                <tr>
                  <th>
                  Oro  (Individual)
                  </th>
                </tr>
                <tr>
                  <td className='precios'>
                    54,00
                  </td>
                </tr>
                <tr>
                  <th>
                  Oro  (Familiar)
                  </th>
                </tr>
                <tr>
                  <td className='precios'>
                    68,00
                  </td>
                </tr>
                <tbody>
                <tr>
                  <td>
                  -Cobertura inmediata por muerte violenta o accidental y después de 180 días por muerte natural.
                  </td>
                </tr>

                <tr>
                  <td>
                  -Servicios de cremación o sepelio, ataúd o urna, levantamiento, morgue, manipulación del cuerpo, arreglo floral, velas, recordatorios, accesorios fúnebres, carroza para sepelio y sala de velación.
                  </td>
                </tr>
                <tr>
                  <td>
                  -Trámite de acta de defunción
                  </td>
                </tr>
                <tr>
                  <td>
                  -Trámite de recuperación de CSS
                  </td>
                </tr>
                <tr>
                  <td>
                  -Ayuda psicológica (3 sesiones)
                  </td>
                </tr>
                <tr>
                  <td>
                  -Gastos de viaje hasta $150 en familiar directo 
                  </td>
                </tr>
                <tr>
                  <td>
                  -Descuentos en consultas odontológicas y médicas*
                  </td>
                </tr>
                <tr>
                  <td>
                  -Cremación de mascotas
                  </td>
                </tr>
                <tr>
                  <td>
                  -Traslado en bus de 11 personas, máximo 80 km en el área.
                  </td>
                </tr>
                <tr>
                  <td>
                  *Pago único
                  </td>
                </tr>
                </tbody>
              </table>

            </p>
          </div>
          <div className="c-accordion__aside">
            <h2 className="c-accordion__title c-accordion__title--hover-hide"></h2>
          </div>
        </a>
      </li>
    </ul>
  );
}

export default Accordion;
