import React, { useEffect } from 'react';
import './Unicos.css'
import Imagen from '../../assets/img/Lo que nos hace diferente/compromiso_comunidad.gif'
import ImagenEmpatia from '../../assets/img/Lo que nos hace diferente/empatia.jpg'
import ImagenCalidad from '../../assets/img/Lo que nos hace diferente/calidad_union.jpg'

const LoQueNosHaceUnicos = () => {
  useEffect(() => {


  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <div className='ContenidoUnicos'>
      <div className='Izquierda'>
        <h5>Compromiso con la Comunidad</h5>
        <p>En Aura Paz, estamos arraigados en la comunidad que servimos. Colaboramos con organizaciones locales y ofrecemos apoyo en momentos de necesidad, retribuyendo a quienes confían en nosotros.</p>
      </div>
      <div className='Derecha'>
        <div className='LogoCompromiso'>

          <img src={Imagen} width="30%" alt="" />
        </div>
      </div>

      <div className='Derecha' id='Computador'>
        <div className='LogoCompromiso'>

          <img src={ImagenEmpatia} width="30%" alt="" />
        </div>
      </div>
      <div className='Izquierda'>
        <h5>Profesionalismo y Empatía</h5>
        <p>Nuestro equipo está compuesto por profesionales experimentados que se dedican a guiar y apoyar a las familias en cada paso del proceso funerario. Desde la planificación hasta los detalles más delicados, estamos aquí para aliviar tus preocupaciones y brindarte consuelo.</p>
      </div>
      <div className='Derecha' id='Moviles'>
        <div className='LogoCompromiso'>

          <img src={ImagenEmpatia} width="30%" alt="" />
        </div>
      </div>
      
      <div className='Izquierda'>
        <h5>Un Toque de Calidez</h5>
        <p>En Aura Paz, creamos un ambiente acogedor para que las familias se reúnan y honren a sus seres queridos con profesionalismo, empatía, compromiso comunitario, transparencia y honestidad.</p>
      </div>
      <div className='Derecha'>
        <div className='LogoCompromiso'>

          <img src={ImagenCalidad} width="30%" alt="" />
        </div>
      </div>




    </div>
  );
}

export default LoQueNosHaceUnicos;
