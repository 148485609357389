import React, { useEffect } from "react";
import imagen1 from "../../assets/img/3d/1.jpg";
import imagen2 from "../../assets/img/3d/2.jpg";
import imagen3 from "../../assets/img/3d/3.jpg";
import imagen4 from "../../assets/img/3d/4.jpg";
import imagen5 from "../../assets/img/3d/5.jpg";
import imagen6 from "../../assets/img/3d/6.jpg";
import imagen7 from "../../assets/img/3d/7jpg.jpg";
import imagen8 from "../../assets/img/3d/8.jpg";
import imagen9 from "../../assets/img/3d/9.png";

import "./3dfotos.css";

const Carousel = () => {
  useEffect(() => {
    // Variables globales
    let radius = 240;
    const autoRotate = true;
    const rotateSpeed = -60;
    const imgWidth = 120;
    const imgHeight = 170;
    const bgMusicURL = "https://api.soundcloud.com/tracks/143041228/stream?client_id=587aa2d384f7333a886010d5f52f302a";
    const bgMusicControls = true;

    const odrag = document.getElementById("drag-container");
    const ospin = document.getElementById("spin-container");
    const aImg = ospin.getElementsByTagName("img");
    const aVid = ospin.getElementsByTagName("video");
    const aEle = [...aImg, ...aVid];

    // Tamaño de las imágenes
    ospin.style.width = imgWidth + "px";
    ospin.style.height = imgHeight + "px";

    // Inicializar el carrusel
    const init = (delayTime) => {
      for (let i = 0; i < aEle.length; i++) {
        aEle[i].style.transform = `rotateY(${i * (360 / aEle.length)}deg) translateZ(${radius}px)`;
        aEle[i].style.transition = "transform 1s";
        aEle[i].style.transitionDelay = delayTime || `${(aEle.length - i) / 4}s`;
      }
    };

    // Aplicar la transformación
    const applyTransform = (obj) => {
      if (tY > 180) tY = 180;
      if (tY < 0) tY = 0;
      obj.style.transform = `rotateX(${-tY}deg) rotateY(${tX}deg)`;
    };

    // Controlar la rotación
    const playSpin = (yes) => {
      ospin.style.animationPlayState = yes ? "running" : "paused";
    };

    let sX, sY, nX, nY, desX = 0, desY = 0, tX = 0, tY = 10;

    // Rotación automática
    if (autoRotate) {
      const animationName = rotateSpeed > 0 ? "spin" : "spinRevert";
      ospin.style.animation = `${animationName} ${Math.abs(rotateSpeed)}s infinite linear`;
    }

    // Agregar música de fondo
    if (bgMusicURL) {
      document.getElementById("music-container").innerHTML += `
        <audio src="${bgMusicURL}" ${bgMusicControls ? "controls" : ""} autoplay loop>
          <p>If you are reading this, it is because your browser does not support the audio element.</p>
        </audio>
      `;
    }

    // Configurar eventos solo para el contenedor de arrastre
    odrag.onpointerdown = function (e) {
      clearInterval(odrag.timer);
      e = e || window.event;
      sX = e.clientX;
      sY = e.clientY;

      document.onpointermove = function (e) {
        e = e || window.event;
        nX = e.clientX;
        nY = e.clientY;
        desX = nX - sX;
        desY = nY - sY;
        tX += desX * 0.1;
        tY += desY * 0.1;
        applyTransform(odrag);
        sX = nX;
        sY = nY;
      };

      document.onpointerup = function () {
        odrag.timer = setInterval(() => {
          desX *= 0.95;
          desY *= 0.95;
          tX += desX * 0.1;
          tY += desY * 0.1;
          applyTransform(odrag);
          playSpin(false);
          if (Math.abs(desX) < 0.5 && Math.abs(desY) < 0.5) {
            clearInterval(odrag.timer);
            playSpin(true);
          }
        }, 17);
        document.onpointermove = document.onpointerup = null;
      };

      return false;
    };

    setTimeout(init, 100);

    return () => {
      // Limpiar eventos y animaciones cuando el componente se desmonte
      odrag.onpointerdown = null;
      document.onpointermove = null;
      document.onpointerup = null;
      clearInterval(odrag.timer);
    };
  }, []);

  return (
    <div>
      <div id="drag-container">
        <div id="spin-container">
          <img
            src={imagen1}
            alt=""
          />
          <img
            src={imagen2}
            alt=""
          />
          <img
            src={imagen3}
            alt=""
          />
          <img
            src={imagen8}
            alt=""
          />
          <img
            src={imagen4}
            alt=""
          />
          <img
            src={imagen5}
            alt=""
          />
          <img
            src={imagen6}
            alt=""
          />
          <img
            src={imagen7}
            alt=""
          />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://api.whatsapp.com/send?phone=50766826114&text=Hola,%20Vi%20su%20página%20web%20y%20estoy%20interesado%20en%20saber%20de%20sus%20servicios" 
          >
            <img
              src={imagen9}
              alt=""
            />
          </a>
          <p>Aura paz Funeraria y sala de velación</p>
        </div>
      </div>
      <div id="music-container"></div>
    </div>
  );
};

export default Carousel;
