import React from 'react';
import './Paquetes.css'; // Asegúrate de tener tu archivo de estilos CSS importado
import './PlanMensual.css'; // Asegúrate de tener tu archivo de estilos CSS importado


function Accordion() {
    return (
        <ul className="c-accordion">
            <li id="Mensual" className="c-accordion__item" style={{ '--cover': `url(https://cdn.pixabay.com/photo/2017/06/20/05/00/death-2421821_1280.jpg)` }}>
                <a href="#Mensual" className="c-accordion__action">
                    <div className="c-accordion__content">
                        <h2 className="c-accordion__title c-accordion__title--hero c-accordion__title--hover-show"> Plan Mensual <br></br><br></br></h2>
                        <p className="c-accordion__description mensualdescrip">
                            <h6>Mínima cuota Mensual por la protección de todo su grupo familiar.</h6><br></br>
                            <table>
                            <tr>
                  <th>
                    Precio Mensual
                  </th>
                </tr>
                <tr>
                  <td className='precios'>
                    $ 8,99
                  </td>
                </tr>
                <br />
                <tbody>
                <tr>
                  <td>
                  -Cobertura inmediata por muerte violenta o accidental y después de 180 días por muerte natural.
                  </td>
                </tr>

                <tr>
                  <td>
                  -Servicios de cremación o sepelio, ataúd o urna, levantamiento, morgue, manipulación del cuerpo, arreglo floral, velas, recordatorios, accesorios fúnebres, carroza para sepelio y sala de velación.
                  </td>
                </tr>
                <tr>
                  <td>
                  -Trámite de acta de defunción
                  </td>
                </tr>
                <tr>
                  <td>
                  -Trámite de recuperación de CSS
                  </td>
                </tr>
                <tr>
                  <td>
                  -Ayuda psicológica (3 sesiones)
                  </td>
                </tr>
                </tbody>
              </table>
                        </p>
                    </div>
                    <div className="c-accordion__aside">
                        <h2 className="c-accordion__title c-accordion__title--hover-hide"></h2>
                    </div>
                </a>
            </li>
            <li id="Urgencia" className="c-accordion__item" style={{ '--cover': `url(https://media.istockphoto.com/id/496793262/es/foto/velas-en-las-manos.jpg?s=612x612&w=0&k=20&c=Jh7_yCFmzTTb0W63lcrZsCx6rNoQdjdzHIUzeIkH38I=)` }}>
                <a href="#MeUrgenciansual" className="c-accordion__action">
                    <div className="c-accordion__content">
                        <h2 className="c-accordion__title c-accordion__title--hero c-accordion__title--hover-show"> SERVICIOS FUNEBRES DE NECESIDAD INMEDIATA  <br></br><br></br></h2>
                        <p className="c-accordion__description mensualdescrip">
                            <table>
                <tr>
                  <th>
                DESDE
                  </th>
                </tr>
                <tr>
                  <td className='precios'>
                  450.00
                  </td>
                </tr>
                <tbody>
     

                <tr>
                  <td>
                  -Servicios Funebres que incluyen:  ataud , levantamiento, morgue, manipulación del cuerpo, arreglo floral, velas, recordatorios, accesorios fúnebres, carroza para sepelio y sala de velación.
                  </td>
                </tr>
                <tr>
                  <td>
                  -Trámite de acta de defunción
                  </td>
                </tr>
                <tr>
                  <td>
                  -Trámite de recuperación de CSS
                  </td>
                </tr>
                </tbody>
              </table>
                        </p>
                    </div>
                    <div className="c-accordion__aside">
                        <h2 className="c-accordion__title c-accordion__title--hover-hide"></h2>
                    </div>
                </a>
            </li>

        </ul>
    );
}

export default Accordion;
