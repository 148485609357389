import React from 'react';
import './LoQueNosHaceDiferentes.css';

import Graficas from '../Graficas/Graficas';


const SitemapDiagram = () => {
    return (
        <div className="LoQueNosHaceDiferentesDiv">
 <Graficas/>
        </div>
    );
};

export default SitemapDiagram;
