import React, { useRef, useEffect } from 'react';
import "./video.css";
import videofinal from "../../assets/Videos/VideoFinal.mp4";

function VideoCover() {
  const videoRef = useRef(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Asegúrate de repetir el video al final
    const videoElement = videoRef.current;
    const handleEnded = () => {
      videoElement.currentTime = 0;
      videoElement.play();
    };

    videoElement.addEventListener('ended', handleEnded);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      videoElement.removeEventListener('ended', handleEnded);
    };
  }, []);

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        autoPlay
        muted
        playsInline
        loop={false} // loop desactivado ya que lo controlamos manualmente
        className="full-width-video"
      >
        <source src={videofinal} type="video/mp4" />
      </video>
    </div>
  );
}

export default VideoCover;
